 .profile-page {
  background: #fff;
  box-shadow: -12px 4px 19px 0px rgba(0, 0, 0, 0.1);
  margin-left: auto;
}
@media (max-width: 767px) {
  .profile-page {
    width: 100%;
  }
}
.profile-page .profile-section1 {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
  background-image: url("../../images/profile/bg-curve.svg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 1rem;
}
.profile-page .profile-section1 .logout-text {
  color: #181818;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  padding-right: 0.8rem;
}
.profile-page .profile-section1 .profile-camera {
  position: relative;
  width: 200px;
  margin: auto;
  padding-top: 5rem;
}
.profile-page .profile-section1 .profile-camera img.upload-img {
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
}
.profile-page .profile-section1 .profile-camera .profile-user {
  border-radius: 200px;
  border: 5px solid #f3f3f3;
}
.profile-page .profile-section2 {
  padding: 1rem 2rem;
}
.profile-page .profile-section2 label {
  color: #181818;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.35px;
}
.profile-page .profile-section2 .MuiTextField-root {
  margin-top: 10px;
  margin-bottom: 0px;
}
.profile-page .profile-section2 .MuiInputBase-root {
  padding: 4.5px 0px;
  border-radius: 10px;
  border: 1px solid #9f9f9f;
  height: 45px;
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.profile-page .profile-section2 .MuiInputBase-root fieldset {
  display: none;
}
.profile-page .profile-section2 .MuiInputBase-root input {
  color: #000;
}
.profile-page .profile-section2 .profile-inner {
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
  padding-bottom: 1rem;
}
@media (max-width: 599px) {
  .profile-page .profile-section2 .profile-inner {
    padding-bottom: 0rem;
  }
}
.profile-page .profile-section2 .profile-inner .MuiGrid-root {
  padding: 0px;
}
@media (max-width: 599px) {
  .profile-page .profile-section2 .profile-inner .MuiGrid-root {
    padding-bottom: 1rem;
  }
}
.profile-page
  .profile-section2
  .profile-inner
  .Organisation
  .MuiInputBase-root {
  padding-left: 5px;
}
.profile-page .profile-section2 .lab-list span {
  padding: 10px 24px;
  border-radius: 6px;
  background: #fff4d0;
  display: inline-block;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  color: #565656;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}
