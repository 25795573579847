 .procedure-table th.MuiTableCell-root {
  background-color: #fff4d0;
  color: #181818;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin: auto;
  width: 200px;
}
.procedure-table th.MuiTableCell-root td:first-child {
  width: auto;
  margin-left: 0px;
}
.procedure-table th.MuiTableCell-root:first-child {
  text-align: left;
  width: 900px;
}
.procedure-table th.MuiTableCell-root:first-child .MuiFormControl-root {
  width: 24%;
}
.procedure-table td.MuiTableCell-root {
  width: 200px;
  text-align: center;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.procedure-table td.MuiTableCell-root:first-child {
  width: 900px;
  text-align: left;
}
.proceduredetails-page {
  position: relative;
}
.proceduredetails-page .top-section,
.proceduredetails-page .edit-details {
  position: fixed;
  background-color: #fff;
  z-index: 9;
}
.proceduredetails-page .top-section {
  top: 65px;
}
.proceduredetails-page .edit-details {
  bottom: 0px;
  z-index: 99 !important;
}
.proceduredetails-page .main-proceduredetails {
  padding: 8rem 1.5rem;
}
@media (max-width: 899px) {
  .proceduredetails-page .main-proceduredetails {
    padding-top: 15px;
  }
}
@media (max-width: 899px) {
  .proceduredetails-page .top-section {
    position: relative;
    width: 100% !important;
    top: 0px !important;
  }
}
main.wide-class .proceduredetails-page .top-section,
main.wide-class .proceduredetails-page .edit-details,
main.wide-class .runzdetails-page .edit-details {
  width: calc(100% - 290px);
}
main.narrow-class .proceduredetails-page .top-section,
main.narrow-class .proceduredetails-page .edit-details,
main.narrow-class .runzdetails-page .edit-details {
  width: calc(100% - 95px);
}
button.edit-btn {
  box-shadow: none !important;
  background: transparent !important;
  padding-right: 0px !important;
  text-transform: capitalize;
  color: #181818;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.3px;
}
.menuItem {
  font-size: 12px;
  border-bottom: 1px solid #f3f3f3;
}
.menuItem:hover {
  background-color: #565656;
}
@media (max-width: 899px) {
  main.wide-class .proceduredetails-page .top-section,
  main.wide-class .proceduredetails-page .edit-details,
  main.wide-class .runzdetails-page .edit-details,
  main.narrow-class .proceduredetails-page .top-section,
  main.narrow-class .proceduredetails-page .edit-details,
  main.narrow-class .runzdetails-page .edit-details,
  main.narrow-class .profile-setting-page .edit-details {
    width: 100% !important;
    z-index: 1;
  }
}
