 .setting-section2 label {
  color: #181818;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.35px;
}
.setting-section2 .MuiTextField-root {
  margin-top: 10px;
  margin-bottom: 0px;
}
.setting-section2 .MuiInputBase-root {
  padding: 4.5px 0px;
  border-radius: 10px;
  border: 1px solid #9f9f9f;
  height: 50px;
  color: #9f9f9f;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}
.setting-section2 .MuiInputBase-root fieldset {
  display: none;
}
.setting-section2 .MuiInputBase-root input {
  color: #000;
}
.setting-section2 .profile-inner {
  margin-top: 0px;
  width: 100%;
  margin-left: 0px;
  padding-bottom: 1rem;
}
@media (max-width: 599px) {
  .setting-section2 .profile-inner {
    padding-bottom: 0rem;
  }
}
.setting-section2 .profile-inner .MuiGrid-root {
  padding: 0px;
}
@media (max-width: 599px) {
  .setting-section2 .profile-inner .MuiGrid-root {
    padding-bottom: 1rem;
  }
}
.setting-section2 .profile-inner .Organisation .MuiInputBase-root {
  padding-left: 5px;
}
@media (max-width: 599px) {
  #panel2bh-content .setting-section2 .profile-inner {
    padding-bottom: 1rem;
  }
}
p.setting-title {
  color: #565656;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  padding: 20px 16px;
}
.setting-sidebar {
  height: calc(100vh - 80px);
  width: 240px;
}
.setting-sidebar .app-drawer-list-item-active {
  background: #ffdc6b;
}
.setting-sidebar .MuiListItemButton-root {
  padding: 10px;
}
.setting-sidebar .MuiListItemButton-root:hover {
  background-color: transparent !important;
}
.setting-sidebar .MuiListItemText-root span {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  white-space: nowrap;
}
.setting-sidebar .app-drawer-list-item-active .MuiListItemText-root span {
  color: #181818;
}
.setting-page .MuiDrawer-docked .MuiDrawer-paper {
  position: relative;
}
.search-field-inner.setting-search .MuiInputBase-root {
  background: #f3f3f3;
  border: 0px;
  width: 100%;
}
.alerts-text {
  margin-top: 1rem;
  line-height: normal;
}
.alerts-text p:first-child {
  color: #565656;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 0.5rem;
}
.alerts-text p:last-child {
  color: #9f9f9f;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.24px;
}
.notific-inner {
  letter-spacing: 0.24px;
  line-height: normal;
  font-size: 16px;
}
.notific-inner p:first-child {
  color: #181818;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 14px;
}
.notific-inner p:last-child {
  color: #565656;
  font-weight: 400;
  font-size: 14px;
}
@media (min-width: 900px) {
  svg.mobile-arrow {
    display: none;
  }
}
@media (max-width: 899px) {
  .mobile-class {
    display: none;
  }
}
.notification-page .p-top {
  padding-top: 0px !important;
  padding-left: 0px !important;
}
@media (max-width: 1199px) {
  .notification-page .p-top {
    padding-top: 16px !important;
  }
}
@media (max-width: 899px) {
  .notification-page .title-main {
    display: block;
  }
}
.profile-setting-page {
  padding-bottom: 5rem;
}
.profile-setting-page .profile-camera {
  position: relative;
  width: 200px;
}
@media (max-width: 1199px) {
  .profile-setting-page .profile-camera {
    margin: auto;
  }
}
.profile-setting-page .profile-camera img.upload-img {
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
}
.profile-setting-page .profile-camera .profile-user {
  border-radius: 200px;
  border: 5px solid #f3f3f3;
}
.profile-setting-page .MuiAccordionDetails-root {
  padding: 10px 0px 0px;
}
.profile-setting-page .edit-details {
  position: fixed;
  bottom: 0px;
  background: #fff;
  margin-left: 3px;
  padding: 1rem;
  width: calc(100% - 530px);
}
main.narrow-class .profile-setting-page .edit-details {
  width: calc(100% - 338px);
}
@media (min-width: 900px) {
  .close-mobile {
    display: none;
  }
}
@media (max-width: 1199px) {
  .profile-setting-page .accordion-section {
    padding-left: 0px;
  }
  .profile-setting-page .title-main {
    display: block;
  }
  .profile-setting-page .title-main button {
    margin-top: 1rem;
  }
}
@media (max-width: 899px) {
  .mobile-arrow {
    margin: 24px 0px 0px 20px;
  }
  .profile-setting-page .edit-details {
    width: calc(100% - 295px);
  }
  main.narrow-class .profile-setting-page .edit-details {
    width: calc(100% - 99px);
  }
  .non-mobile-class,
  .mobile-class {
    position: fixed;
    background: #fff;
    z-index: 9;
    overflow-y: auto;
  }
  .close-mobile {
    text-align: right;
    position: absolute;
    right: 9px;
    top: 10px;
  }
  .side-hide {
    display: none;
  }
  .mobile-class.side-hide {
    display: block;
    position: fixed;
    z-index: 1;
    height: 100%;
  }
  .side-show {
    position: fixed;
    z-index: 3;
    height: 100%;
  }
}
.user-setting-page .table-outer .MuiTableContainer-root {
  border-radius: 10px;
}
.user-setting-page .search-field-inner .MuiInputBase-root {
  width: auto;
}
.setting-addnew .MuiTextField-root {
  margin: 0px;
}
.setting-addnew .MuiInputBase-root {
  margin-top: 10px;
  margin-bottom: 20px;
}
.bg-gray-input .MuiInputBase-root {
  background: #f3f3f3;
}
p.change-you {
  color: #9f9f9f;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 1rem;
}
@media (max-width: 767px) {
  .user-setting-page .title-main {
    display: block;
  }
  .user-setting-page .title-main button {
    margin-top: 1rem;
  }
}
.role-setting-page {
  position: relative;
  padding-left: 4px;
}
.role-setting-page .edit-details {
  position: sticky;
  left: 0;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 2;
}
@media (max-width: 1199px) {
  .role-setting-page .title-main {
    display: block;
  }
  .role-setting-page .title-main button {
    margin-top: 1rem;
  }
}
p.Access-control {
  color: #565656;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  padding-bottom: 15px;
}
.role-table {
  margin-right: -30rem;
}
.role-table th.MuiTableCell-root {
  background-color: #fff4d0;
  color: #181818;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  padding: 10px 16px;
}
.role-table th.MuiTableCell-root:first-child {
  text-align: left;
}
.role-table th.MuiTableCell-root {
  width: 200px;
}
.role-table th.MuiTableCell-root:first-child {
  width: 900px;
}
.role-table td.MuiTableCell-root {
  width: 200px;
  text-align: center;
  color: #565656;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
  padding: 5px 0px;
}
.role-table td.MuiTableCell-root:first-child {
  width: 900px;
  text-align: left;
  white-space: nowrap;
}
.role-table td.MuiTableCell-root .MuiRadio-root svg {
  width: 30px;
  height: 30px;
}
.role-table td.MuiTableCell-root .Mui-checked {
  color: #ffc60b !important;
}
.role-table td.procedure-profile {
  white-space: normal !important;
  border: 0px;
  padding: 15px 0px 15px 0px;
}
.role-table td.procedure-profile p {
  letter-spacing: 0.24px;
  line-height: normal;
}
.role-table td.procedure-profile p:first-child {
  color: #181818;
  font-weight: 600;
}
.role-table td.procedure-profile p:last-child {
  color: #565656;
  font-weight: 400;
  margin-top: 0.5rem;
}
